import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import isMicrosite from '@/utils/isMicrosite';
import api from '@/api/products';
import getProductDetailServerSide from '@/utils/pagesServerSide/getProductDetailServerSide';
import getProductsServerSide from '@/utils/pagesServerSide/getProductsServerSide';
import getHomeServerSide from '@/utils/pagesServerSide/getHomeServerSide';
import {
  Home,
  ProductSheet,
  ProductsGalleryPage
} from '@/components/templates';
import { getStore } from '@/utils/getStoreId';

import useTrackingQueue from '@/hooks/useTrackingQueue';
import createUUID from '@/utils/createUUID';
import renderGalleryInfo from '@/utils/renderGalleryInfo';

const isMicrositePage = (props) => {
  const { page, landingInfo, landing } = props;
  const { orderBy } = useSelector((state) => state.products);
  const { executeEvent } = useTrackingQueue();
  useEffect(() => {
    if (landing && page === 'products') {
      executeEvent('viewLanding', {
        landing,
        landingEventID: landingInfo.landingEventID,
        data: landingInfo
      });
    }
  }, []);
  const renderPage = () => {
    if (page === 'home') {
      return <Home {...props} />;
    }
    if (page === 'products') {
      const { gender, keywords, initialReduxState } = props;

      const { title, description } = renderGalleryInfo({
        gender,
        initialReduxState,
        keywords,
        landing,
        orderBy
      });
      return (
        <ProductsGalleryPage
          landingDescription={landingInfo.description}
          options={initialReduxState.products.filtersOptions}
          pagination={initialReduxState.products.pagination}
          title={title}
          description={description}
        />
      );
    }
    return <ProductSheet />;
  };
  return renderPage();
};
export default isMicrositePage;

export async function getServerSideProps(ctx) {
  const { slugMicrosite } = ctx.params;

  const storeId = getStore(ctx?.resolvedUrl);
  const isSlugMicrosite = await isMicrosite(slugMicrosite);
  // Es un micrositio
  if (isSlugMicrosite) {
    return getHomeServerSide(ctx, storeId);
  }
  // Es una landing
  const landingEventID = createUUID();
  const isLanding = await api.validateSlug(
    storeId,
    landingEventID,
    slugMicrosite
  );

  if (isLanding.landing) {
    return getProductsServerSide(ctx, '', slugMicrosite, {
      landingEventID,
      ...isLanding
    });
  }
  // Es un producto

  const splitURL = slugMicrosite.replace(/[^0-9--]/g, '').split('-');
  const [id] = splitURL.slice(-1);
  // url with productColorID
  if (id.length > 5) {
    const urlParts = slugMicrosite.split('-');
    return {
      redirect: {
        destination: `/${urlParts.slice(0, urlParts.length - 1).join('-')}`,
        permanent: true
      }
    };
    // id = splitURL.slice(-2, -1);
  }
  if (id) {
    return getProductDetailServerSide(ctx, slugMicrosite, id, storeId);
  }

  if (!ctx.resolvedUrl.includes('/p/')) {
    return {
      redirect: {
        destination: `${ctx.resolvedUrl}/p/1`,
        permanent: false
      }
    };
  }
  // La URL es incorrecta -> Home
  return {
    redirect: {
      destination: '/',
      permanent: false
    }
  };
}
